<template>
    <div style="min-width: 1500px;">
        <div class="navbarBox" style="position: relative">
            <div class="flexSB" style="width: 80%; min-width: 500px; margin: 0 auto;position: relative;">
                <div style="width: 200px">
                    <img alt src="@/assets/logo.png" srcset style="width: 100%" />
                </div>
                <div class="flexSB" style="width: 1200px">
                    <div :id="'title' + index" :key="index" @mouseenter="handleMouseEnter(index)" @mouseleave="handleMouseLeave(index)" class="hand" v-for="(itme, index) in navbarList">
                        <div :class="{ selectBox: select == index }" @click="onJump(itme.url)" class="title">{{ itme.title }}</div>
                    </div>

                    <div class="flexSB" style="width: 100px;" v-if="!avatar">
                        <div :id="'title' + itme.id" :key="index" @mouseenter="handleMouseEnter(itme.id)" @mouseleave="handleMouseLeave(itme.id)" class="hand" v-for="(itme, index) in loginList">
                            <div :class="{ selectBox: select == itme.id }" @click="onJump(itme.url)" class="title">{{ itme.title }}</div>
                        </div>
                    </div>

                    <div v-else>
                        <el-avatar :size="50" :src="avatar"></el-avatar>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Avatar } from 'element-ui'
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters(['getThemeColor']),
    },
    props: ['select'],
    data() {
        return {
            show: false,
            avatar: '',
            // avatar:"https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
            navbarList: [
                // {
                //   id: 1,
                //   title: "短视频",
                //   url: "/",
                // },
                // {
                //   id: 1,
                //   title: "长视频",
                //   url: "/longVideo",
                // },
                {
                    id: 1,
                    title: '首页',
                    url: '/',
                },
                {
                    id: 1,
                    title: '网络表演',
                    url: '/home',
                },

                {
                    id: 1,
                    title: '充值中心',
                    url: '/recharge',
                },
                {
                    id: 1,
                    title: '家长监护',
                    url: '/parent',
                },
                {
                    id: 1,
                    title: '用户纠纷处理',
                    url: '/dispute',
                },
                {
                    id: 1,
                    title: '未成年人防沉迷',
                    url: '/deep',
                },
                {
                    id: 1,
                    title: '网络表演承诺书',
                    url: '/liveCommitment',
                },
                {
                    id: 1,
                    title: '网络表演公约',
                    url: '/liveConvention',
                },
                {
                    id: 1,
                    title: '联系我们',
                    url: '/about',
                },
            ],
            loginList: [
                {
                    id: 9,
                    title: '登录',
                    url: '/login',
                },
                {
                    id: 10,
                    title: '注册',
                    url: '/register',
                },
            ],
        }
    },
    mounted() {
        this.avatar = localStorage.getItem('avatar')
    },
    methods: {
        onJump(url) {
            this.$router.push(url)
        },

        handleMouseEnter(index) {
            console.log('划入', index)
            this.hoverShow = index
            // 获取元素
            const myElement = document.getElementById('title' + index)

            // 改变元素颜色为红色
            myElement.style.color = this.getThemeColor
            if (index == 4) {
                this.show = true
            }
        },

        handleMouseLeave(index) {
            console.log(index)
            this.hoverShow = -1
            // this.hoverShow = index
            // 获取元素
            const myElement2 = document.getElementById('title' + index)

            // 改变元素颜色为红色
            myElement2.style.color = '#2c2c2c'
            this.show = false
        },

        // handleMouseEnter(index) {
        //   if (index == 4) {
        //     this.show = true;
        //   } else {
        //     this.show = false;
        //   }
        // },

        // handleMouseLeave(index) {
        //   this.show = false;
        // },
    },
}
</script>

<style lang="scss" scoped>
.max-width {
    margin: 0 auto;
}

.navbarBox {
    height: 70px;
    background-color: #fff;
    margin-top: -10px;
    padding-top: 13px;
    color: #202020;
}

.flexSB {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.inputBox {
    width: 250px;
    border: none;
    height: 30px;
    border-radius: 10px;
    background-color: rgb(242, 242, 242);
}

.selectBox {
    color: #ffd727;
}

.scanCodeBox {
    width: 180px;
    height: 180px;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    right: 0;
    top: 50px;
    border-radius: 10px;
}

.codeBox {
    width: 150px;
    height: 150px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    border: 1px solid #585858;
    margin: auto;
    margin-top: 13px;
    overflow: hidden;
}
</style>