import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // }
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home.vue')
  },
  ,
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about.vue')
  },
  {
    path: '/play',
    name: 'play',
    component: () => import('../views/play.vue')

  },
  {
    path: '/scanCode',
    name: 'scanCode',
    component: () => import('../views/scanCode.vue')
    
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: () => import('../views/recharge.vue')
    
  },
  {
    path: '/parent',
    name: 'parent',
    component: () => import('../views/parent.vue')
  },
  {
    path: '/dispute',
    name: 'dispute',
    component: () => import('../views/dispute.vue')
  },
  {
    path: '/deep',
    name: 'deep',
    component: () => import('../views/deep.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
    
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/register.vue')
    
  },
  {
    path: '/authentication',
    name: 'authentication',
    component: () => import('../views/authentication.vue')
  },
  {
    path: '/authentication',
    name: 'authentication',
    component: () => import('../views/authentication.vue')
  },
  {
    path: '/liveCommitment',
    name: 'liveCommitment',
    component: () => import('../views/liveCommitment.vue')
  },
  {
    path: '/liveConvention',
    name: 'liveConvention',
    component: () => import('../views/liveConvention.vue')
  },
  {
    path: '/urheberrecht',
    name: 'urheberrecht',
    component: () => import('../views/urheberrecht.vue')
  },
  {
    path: '/protokoll',
    name: 'protokoll',
    component: () => import('../views/protokoll.vue')
  },
  {
    path: '/kontakt',
    name: 'kontakt',
    component: () => import('../views/kontakt.vue')
  },
  {
    path: '/architektur',
    name: 'architektur',
    component: () => import('../views/architektur.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
