
<template>
    <div>
        <div class="maxBox flexFST">
            <div class="maxBox" style="height: 800px; margin-top: 25px">
                <div :style="dplayerData.style" class="dplayerBox" id="dplayer"></div>
            </div>

            <!-- 视频评论 -->
            <div class="plunBox" v-if="!isLive">
                <div class="title">评论</div>
                <div style="color: #fff; margin-top: 20px">全部评论{{ "(" + commentList.length + ")" }}</div>

                <div class="infinite-list" v-if="commentList.length > 0">
                    <div :key="index" style="margin-top: 20px" v-for="(item, index) in commentList">
                        <div class="flexFS">
                            <div class="headBoxImg">
                                <img :src="item.userInfo.avatar" style="width: 100%" />
                            </div>

                            <div>
                                <div class="titleName" style="color: #bdbdbd">{{ item.userInfo.name }}</div>
                            </div>
                        </div>
                        <!-- 正文 -->
                        <div class="dateText" style="margin-top: -10px">{{ item.content }}</div>

                        <div class="dateText" style="font-size: 12px; color: #c4c4c4; margin: 20rpx 0">{{ item.create_time }}</div>

                        <!-- 展开回复的评论 -->
                        <div style="margin-left: 50px" v-if="item.child_total > 0 && showReply == index">
                            <div :key="index2" style="margin-top: 20px" v-for="(item2, index2) in sonCommentList">
                                <div class="flexFS">
                                    <div class="headBoxImg">
                                        <img :src="item2.userInfo.avatar" style="width: 100%" />
                                    </div>

                                    <div>
                                        <div class="titleName" style="color: #bdbdbd">{{ item2.userInfo.name }}</div>
                                    </div>
                                </div>
                                <!-- 正文 -->
                                <div class="dateText" style="margin-top: -10px">{{ item2.content }}</div>

                                <div class="dateText" style="font-size: 12px; color: #c4c4c4; margin: 20rpx 0">{{ item2.create_time }}</div>
                            </div>
                        </div>
                        <!-- 收起回复 -->
                        <div @click="showReply = -1" class="reply hand" v-if="showReply == index">
                            <i class="el-icon-minus"></i>
                            收起
                            <i class="el-icon-arrow-up"></i>
                        </div>
                        <!-- 展开回复 -->
                        <div @click="sonCommentListFun(item.id, index)" class="reply hand" v-else>
                            <div v-if="item.child_total > 0">
                                <i class="el-icon-minus"></i>
                                <i class="el-icon-minus"></i>
                                展开{{ item.child_total }}条回复
                                <i class="el-icon-arrow-down"></i>
                            </div>
                        </div>
                    </div>

                    <div style="margin: 30px auto; width: 150px" v-show="moreBtnShow">
                        <el-button @click="more" round style="width: 150px; margin-bottom: 300px">点击加载更多评论</el-button>
                    </div>
                </div>
                <div style="text-align: center; margin-top: 50px; color: #fff" v-else>暂无更多评论~~~</div>
            </div>

            <!-- 网络表演评论 -->
            <div class="plunBox2" v-else>
                <p style="color: rgb(187,185,194);font-size:14px;line-height:20px">
                    官方提醒:网络表演间严禁出现违法违规、低俗谩骂等不良内容，一经发现，超管将对账号予以禁言、封停、永久封禁处理。
                    官方提醒:请用户通过官方渠道进行金币充值，谨防受骗。未成年用户请勿在网络表演间进行任何充值消费行为。
                    欢迎来自火星的游客进入频道
                </p>
                <div :key="index" class="chatBox" v-for="(item, index) in chatListShow">
                    <div style="color: rgb(187,185,194);">
                        {{ item.name + ": " }}
                        <span style="color:#e4e0e0">
                            {{ item.content
                            }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <!-- 作品名 -->
        <div class="name">{{ name }}</div>
    </div>
</template>

<script>
import DPlayer from 'dplayer'
import dplayer from '@/components/dplayer/dplayer'
export default {
    props: ['worksId', 'isLive'],
    components: {
        dplayer,
    },
    data() {
        return {
            dplayerData: {
                style: 'width:100%;height:672px;position: absolute;bottom: 0;right: 0;',
            },
            info: { video: '视频链接', pic: '封面图链接' },
            page: 1,
            pageHF: 1,
            showReply: -1,
            name: '',
            type: -1,
            dplayerShow: true,
            commentList: [],
            sonCommentList: [],
            moreBtnShow: true,
            pageSize: 30,
            // isLive: isLive, //切换视频或网络表演
            chatList: [],
            chatListShow: [],
        }
    },
    mounted() {
        this.dp = new DPlayer({
            container: document.getElementById('dplayer'),
            theme: '#b7daff', // 风格颜色，例如播放条，音量条的颜色
            loop: false, // 是否自动循环
            lang: 'zh-cn', // 语言，'en', 'zh-cn', 'zh-tw'
            hotkey: true, // 是否支持热键，调节音量，播放，暂停等
            preload: 'auto', // 自动预加载
            mutex: true, //互斥，阻止多个播放器同时播放，当前播放器播放时暂停其他播放器
            live: this.isLive,
            preventClickToggle: this.isLive,
            video: {
                url: '', // 播放视频的路径
                pic: '', // 视频封面图片
            },
        })
    },
    methods: {
        empty() {
            this.name = ''
            this.dplayerShow = true
            this.switchVideo('')
            this.chatListShow = []
            this.commentList = []
            console.log('清空子组件')
        },
        childMethod() {
            if (this.worksId) {
                this.getWorksDetails()
                this.getComment()
            } else {
                //网络表演
            }

            this.liveChat()
        },
        sonCommentListFun(id, index) {
            this.getCommentHF(id, index)
        },

        //网络表演的评论
        liveChat() {
            let that = this
            for (let i = 0; i < that.chatList.length; i++) {
                ;(function (index) {
                    setTimeout(() => {
                        that.chatListShow.push(that.chatList[index])
                    }, 2000 * (index + 1))
                })(i)
            }
        },

        //查询视频评论回复
        getCommentHF(parent, index) {
            let that = this
            console.log('that.worksId', that.worksId)

            let parameter = {
                creationid: that.worksId, //作品ID
                page: that.pageHF,
                pageSize: 30,
                parent: parent,
            }
            that.apis.getComment(parameter).then((res) => {
                if (parent) {
                    //查询单挑评论的回复
                    that.sonCommentList = res.data
                    that.showReply = index
                } else {
                    //查询所有评论
                    if (that.page == 1) {
                        that.commentList = res.data
                        console.log('成功')
                    } else {
                        //上拉加载
                        that.commentList = [...that.commentList, ...res.data]
                        that.loading = false
                    }
                }
            })
        },

        //查询视频评论
        getComment(index) {
            let that = this
            let parameter = {
                creationid: that.worksId, //作品ID
                page: that.page,
                pageSize: 15,
            }
            that.apis.getComment(parameter).then((res) => {
                //如果评论没有了就隐藏按钮
                if (res.data.length < 15) {
                    // this.$message({
                    //     message: "暂无更多消息",
                    //     type: "warning",
                    // });
                    that.moreBtnShow = false
                }
                //查询所有评论
                if (that.page == 1) {
                    that.commentList = res.data
                    console.log('成功')
                    that.loading = false
                } else {
                    if (res.data.length < 15) {
                        // this.$message({
                        //     message: "暂无更多消息",
                        //     type: "warning",
                        // });
                        that.moreBtnShow = false
                    }
                    //上拉加载
                    that.commentList = [...that.commentList, ...res.data]
                    that.loading = false
                }
            })
        },

        //查询作品详情
        getWorksDetails(index) {
            let that = this
            let parameter = {
                id: that.worksId, //作品ID
            }
            that.apis.getWorksDetails(parameter).then((res) => {
                that.info.video = res.data.media_urls[0]
                that.name = res.data.name
                that.dplayerShow = false
                that.switchVideo(res.data.media_urls[0])
                that.dp.play()
            })
        },

        //加载更多
        more() {
            console.log()
            this.page++
            this.getComment()
        },
        // 切换视频源事件
        switchVideo(item) {
            this.dp.switchVideo({
                url: item,
                // pic: item.imgUrl,
            })
        },
        // 暂停事件
        pause() {
            this.dp.pause()
        },
    },
}
</script>

<style  scoped>
.dplayerBox {
    height: 800px;
    overflow: hidden;
}

.plunBox2 {
    height: 800px;
    width: 660px;
    background-color: rgba(47, 39, 78, 0.5);
    margin-top: 25px;
    padding: 0 20px;
    overflow: auto;
    margin-right: 60px;
    position: relative;
    /* 使伪元素相对于body定位 */
    z-index: 1;
    background-attachment: fixed;
    /* 设置背景图片固定 */
    /* 从左上到右下的对角渐变 */
    background: linear-gradient(to bottom right, rgba(47, 39, 78, 1), rgba(24, 22, 36, 1));
}

.plunBox2::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.plunBox {
    height: 800px;
    width: 660px;
    background-color: rgba(0, 0, 0, 0.5);
    margin-top: 25px;
    padding: 0 20px;
    overflow: auto;
    margin-right: 60px;
    position: relative;
    /* 使伪元素相对于body定位 */
    z-index: 1;
    background-attachment: fixed;
    /* 设置背景图片固定 */
    /* 从左上到右下的对角渐变 */
}

.plunBox::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.plunBox::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://gd-hbimg.huaban.com/6307ee6f487cd986ca22d93935f7f6c61645a86c11244b-db68ki_fw658webp');
    filter: blur(300px);
    z-index: -1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.title {
    text-align: center;
    padding: 15px 0;
    color: #fff;
    font-size: 18px;
    border-bottom: 1px solid #555;
}

.headBoxImg_img {
    border-radius: 3px;
    overflow: hidden;
}

.headBoxImg {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    margin-right: 10px;
}

.titleName {
    font-size: 26px;
    margin-bottom: 20px;
}

.dateText {
    font-size: 14px;
    color: #ffffff;
    margin-left: 50px;
}

.titleName {
    color: #fff;
    font-size: 14px;
}

.maxBox {
    background-color: #050d0f;
    height: 94.5vh;
    overflow: hidden;
    margin-top: 50px;
    width: 100%;
}

.reply {
    color: #bdbdbd;
    font-size: 12px;
    margin-left: 50px;
    margin-top: 10px;
}

#dplayer {
    width: 1250px !important;
    height: 715px !important;
    position: absolute;
    top: 50px;
    left: 50px;
}

.maxBox {
    position: relative;
    z-index: 1;
}

.maxBox::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://gd-hbimg.huaban.com/0da9c10662e3e0f0331c8a72c969fd0b9e3ef85b1b2ed3-vnFhyq_fw658webp');
    filter: blur(200px);
    z-index: -1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.chatBox {
    margin: 10px 0;
    font-size: 14px;
    color: rgb(187, 185, 194);
}

/deep/ .dplayer-controller .dplayer-icons .dplayer-icon {
    /* display: none; */
}
</style>