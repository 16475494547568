<template>
    <div class="box" style="width: 100%; padding: 20px">
        <div>
            <div class="title">热门排行榜</div>
            <div>
                <div>
                    <!-- 主播 -->
                    <div style="margin-top: 20px;">
                        <div :key="index" in style="position: relative;" v-for="(itme, index) in dataList">
                            <div style="margin-bottom: 20px;" v-if="index < 7">
                                <div class="flexFS" style="width: 100%;">
                                    <div style="color:#555;font-size:14px">{{ index + 1 }}</div>
                                    <div @mouseenter="handleMouseEnter(index)" @mouseleave="handleMouseLeave(index)" class="headImgBox hand" style="margin: 0 5px;">
                                        <el-popover placement="bottom" trigger="hover" visible-arrow="false" width="150px">
                                            <!-- 鼠标移入显示的详情 -->
                                            <div :style="showDetailsColor" class="showDetails">
                                                <div class="headImgBox2" style="margin: 0 auto;">
                                                    <img :src="itme.thumbnail" alt srcset style="width: 100%;" />
                                                </div>
                                                <div style="text-align: center;font-size:16px">{{ itme.name }}</div>
                                                <div class="flexSB" style="width: 200px;margin: 10px auto;">
                                                    <div>账号： {{ itme.number }}</div>
                                                    <div>{{ itme.city }}</div>
                                                </div>
                                                <div class="flexSB" style="width: 200px; margin: 10px auto;">
                                                    <div class="flexSB" style="width: 70px;">
                                                        <div>
                                                            <i class="el-icon-user" style="font-size:20px"></i>
                                                        </div>
                                                        <div>{{ itme.people }}</div>
                                                    </div>
|
                                                    <div class="flexFS" style="width: 100px">
                                                        <div>
                                                            <i class="el-icon-star-off" style="font-size:20px"></i>
                                                        </div>
                                                        <div>{{ itme.heat }}</div>
                                                    </div>
                                                </div>
                                                <!-- 看网络表演 -->
                                                <div :style="{ background: $store.state.personality }" @click="handleChildData(itme.media)" class="liveBtn hand">看网络表演</div>
                                            </div>
                                            <el-avatar :src="itme.thumbnail" alt slot="reference" srcset style="width: 100%;height: 100%;"></el-avatar>
                                        </el-popover>
                                    </div>

                                    <div style="width: 150px;margin-left:10px">
                                        <div>{{ itme.name }}</div>
                                        <div :style="{ color: $store.state.personality }">{{ itme.time }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 主播 -->
            <!-- <anchor :dataList="dataList"></anchor> -->

            <div class="line"></div>
        </div>
        <div @click="handleChildData()" class="title">头条排行榜</div>

        <!-- 主播 -->
        <!-- <anchor :dataList="dataList"  @custom="handleChildData()" ></anchor> -->
        <div>
            <div>
                <!-- 主播 -->
                <div style="margin-top: 20px;">
                    <div :key="index" in style="position: relative;" v-for="(itme, index) in dataList">
                        <div style="margin-bottom: 20px;" v-if="index < 7">
                            <div class="flexFS" style="width: 100%;">
                                <div style="color:#555;font-size:14px">{{ index + 1 }}</div>
                                <div @mouseenter="handleMouseEnter(index)" @mouseleave="handleMouseLeave(index)" class="headImgBox hand" style="margin: 0 5px;">
                                    <el-popover placement="bottom" trigger="hover" visible-arrow="false" width="150px">
                                        <!-- 鼠标移入显示的详情 -->
                                        <div :style="showDetailsColor" class="showDetails">
                                            <div class="headImgBox2" style="margin: 0 auto;">
                                                <img :src="itme.thumbnail" alt srcset style="width: 100%;" />
                                            </div>
                                            <div style="text-align: center;font-size:16px">{{ itme.name }}</div>
                                            <div class="flexSB" style="width: 200px;margin: 10px auto;">
                                                <div>账号： {{ itme.number }}</div>
                                                <div>{{ itme.city }}</div>
                                            </div>
                                            <div class="flexSB" style="width: 200px; margin: 10px auto;">
                                                <div class="flexSB" style="width: 70px;">
                                                    <div>
                                                        <i class="el-icon-user" style="font-size:20px"></i>
                                                    </div>
                                                    <div>{{ itme.people }}</div>
                                                </div>
|
                                                <div class="flexFS" style="width: 100px">
                                                    <div>
                                                        <i class="el-icon-star-off" style="font-size:20px"></i>
                                                    </div>
                                                    <div>{{ itme.heat }}</div>
                                                </div>
                                            </div>
                                            <!-- 看网络表演 -->
                                            <div :style="{ background: $store.state.personality }" @click="handleChildData(itme.media)" class="liveBtn hand">看网络表演</div>
                                        </div>
                                        <el-avatar :src="itme.thumbnail" alt slot="reference" srcset style="width: 100%;height: 100%;"></el-avatar>
                                    </el-popover>
                                </div>

                                <div style="width: 150px;margin-left:10px">
                                    <div>{{ itme.name }}</div>
                                    <div :style="{ color: $store.state.personality }">{{ itme.time }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="background-color: #d4d4d4;height: 1px;"></div>
    </div>
</template>

<script>
import anchor from '@/components/followWithInterest/anchor.vue'
import list from '@/api/request.json'
export default {
    components: {
        anchor,
    },
    computed: {
        showDetailsColor() {
            return { border: '1px solid' + this.$store.state.personality }
        },
    },
    data() {
        return {
            show: -1,
            dataList: list.main,
        }
    },
    methods: {
        handleChildData(data) {
            this.$router.push('/live')
            // 在这里可以处理子组件传递的数据
            console.log('followWithInterest', data)
            this.$emit('custom-event', data)
        },
        handleMouseEnter(index) {
            console.log('划入', index)
            this.show = index
        },
        handleMouseLeave(index) {
            console.log(index)
            this.show = -1
        },
    },
}
</script>

<style lang="scss" scoped>
.title {
    font-size: 20px;
    font-weight: 700;
}

.box {
    width: 100%;
    padding: 20px 10px;
    background-color: #fff;
    border-radius: 20px;
    font-size: 12px;
    color: #444444;
}

.line {
    background-color: #e6e6e6;
    height: 1px;
    margin-bottom: 20px;
}

.title {
    font-size: 20px;
    font-weight: 700;
}

.box {
    width: 100%;
    padding: 20px 10px;
    background-color: #fff;
    border-radius: 20px;
    font-size: 12px;
    color: #444444;
}

.headImgBox {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    overflow: hidden;
}

.headImgBox2 {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    overflow: hidden;
}

.tuijuan {
    color: rgb(245, 166, 35);
}

.showDetails {
    width: 260px;
    height: 230px;
    background-color: #fff;
    border-radius: 5px;
    // padding: 30px;
    position: absolute;
    top: -5px;
    left: -50px;
    z-index: 99;
    overflow: hidden;
    padding-top: 30px;
}

.liveBtn {
    width: 100%;
    height: 35px;
    margin-top: 42px;
    color: #222222;
    text-align: center;
    line-height: 35px;
}
</style>