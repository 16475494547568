<template>
  <div style="margin-top: -10px">
    <navbar select="0" class="navbarBox"></navbar>

    <!-- 视频 -->
    <videoCom type="short" style="width: 80%;margin: 100px auto;"></videoCom>

    <bottom class="bottomBox" v-show="vShow"></bottom>
  </div>
</template>

<script>
// @ is an alias to /src
import navbar from "@/components/navbar/navbar.vue";
import bottom from "@/components/bottom/bottom.vue";
import videoCom from "@/components/videoCom/videoCom.vue";
import { mapState } from "vuex";

export default {
  name: "home",
  components: {
    navbar,
    bottom,
    videoCom,
  },
  data() {
    return {
      vShow:false
    };
  },

  mounted() {
    // this.getShort();
    setTimeout(()=>{
      this.vShow = true
    },1500)
  },

  created() {},

  methods: {},
};
</script>

<style  lang="scss" scoped>
.imgBox {
  width: 140px;
  height: 140px;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>