<template>
    <div class="maxBox" style="height: 800px;margin-top: 25px;">
        <div :style="dplayerData.style" class="dplayerBox" id="dplayer"></div>
    </div>
</template>
 
<script>
import DPlayer from 'dplayer'
export default {
    name: 'dplayer',
    props: {
        dplayerData: Object,
    },
    data() {
        return {
           dp:null
        }
    },
    mounted() {
        this.dp = new DPlayer({
            container: document.getElementById('dplayer'),
            theme: '#b7daff', // 风格颜色，例如播放条，音量条的颜色
            // loop: false, // 是否自动循环
            lang: 'zh-cn', // 语言，'en', 'zh-cn', 'zh-tw'
            hotkey: true, // 是否支持热键，调节音量，播放，暂停等
            preload: 'auto', // 自动预加载
            mutex: true,

            //
            video: {
                url: '', // 播放视频的路径
                defaultQuality: 0, // 默认是HD
                pic: '', // 视频封面图片
            },
            subtitle: {
                url: 'https://api.prprpr.me/dplayer/',
                type: 'webvtt',
                fontSize: '25px',
                bottom: '10%',
                color: '#b7daff',
            },
            danmaku: {
                id: '9E2E3368B56CDBB4',
                api: 'https://api.prprpr.me/dplayer/',
                token: 'tokendemo',
                maximum: 1000,
                addition: ['https://api.prprpr.me/dplayer/v3/bilibili?aid=[aid]'],
                user: 'DIYgod',
                bottom: '15%',
                unlimited: true,
                speedRate: 0.5,
            },
            contextmenu: [
                {
                    text: 'custom1',
                    link: 'https://github.com/DIYgod/DPlayer',
                },
                {
                    text: 'custom2',
                    click: (player) => {
                        console.log(player)
                    },
                },
            ],
        })
    },
    created() {},
    methods: {
        // 切换视频源事件
        switchVideo(item) {
            this.dp.switchVideo({
                url: item.video,
                pic: item.imgUrl,
            })
        },
        // 暂停事件
        pause() {
            this.dp.pause()
        },
    },
}
</script>
 
<style lang="scss" scoped>
#dplayer {
    width: 1250px !important; // 设置宽度
    height: 715px !important; // 设置高度
    position: absolute;
    top: 50px;
    left: 50px;
}

.maxBox {
    // background: radial-gradient(ellipse, #585858, #0d1f24);
    position: relative;
    z-index: 1;
}
.maxBox::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://gd-hbimg.huaban.com/0da9c10662e3e0f0331c8a72c969fd0b9e3ef85b1b2ed3-vnFhyq_fw658webp');
    filter: blur(200px);
    z-index: -1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
</style>