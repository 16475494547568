<template>
  <el-row v-loading="loading">

    <!-- 左边图片 -->
    <div class="flex">
      <div>
        <div class="flex">
          <div  class="maxBox" style="" v-lazy-container="{ selector: 'img' }">
            <!-- <div class="maskBox"></div> -->
            <img  v-if="dataList[0]" class="img" :data-src="dataList[0].first_media" alt="" srcset=""
              @click="handleClick(dataList[0].id, type)">
          </div>
          <!-- 中间视频 -->
          <el-row>
            <div class="flexFS" style="width: 770px;">
          <div class=" videoBox hand" v-if="index < 21 && index > 0" @click="handleClick(itme.id, type)"
            v-for="(itme, index) in dataList" :key="index" @mouseenter="handleMouseEnter(index)"
            @mouseleave="handleMouseLeave">
            <!-- <img :data-src="itme.first_media" style="width: 100%;" v-if="itme.first_media" /> -->
            <div class="demo-image">
              <div class="" style="width: 155px;height: 155px">
                <el-image style="width: 100%; height: 100%" :src="itme.first_media" fit="cover"></el-image>
              </div>
            </div>
            <!-- 移入的时候显示的信息 -->
            <div class="videoHoverBox" v-if="hoverShow == index">
              <div>{{ itme.userInfo.name }}</div>
              <div style="margin: 5px 0;">{{ itme.city }}</div>
              <div>🔥 {{ itme.like_num }}</div>
            </div>

          </div>
        </div>

            <!-- <div style="margin: 30px auto; width: 150px">
          <el-button round style="width: 150px;" @click="more">点击加载更多</el-button>
        </div> -->
          </el-row>
        </div>

        <div v-if="dataList.length > 20">
          <div class="title">
            🔥更多精彩
          </div>
        </div>

        <!-- 第二页视频 -->
        <div class="flexFS" style="width: 100%;">
          <div class=" videoBox hand videoBox2" v-if="index > 20" @click="handleClick(itme.id, type)"
            v-for="(itme, index) in dataList" :key="index" @mouseenter="handleMouseEnter(index)"
            @mouseleave="handleMouseLeave">
            <!-- <img :data-src="itme.first_media" style="width: 100%;" v-if="itme.first_media" /> -->
            <div class="demo-image">
              <div class="" style="width: 155px;height: 155px">
                <el-image style="width: 100%; height: 100%" :src="itme.first_media" fit="cover"></el-image>
              </div>
            </div>
            <!-- 移入的时候显示的信息 -->
            <div class="videoHoverBox" v-if="hoverShow == index">
              <div>{{ itme.userInfo.name }}</div>
              <div style="margin: 5px 0;">{{ itme.city }}</div>
              <div>🔥 {{ itme.like_num }}</div>
            </div>

          </div>
        </div>


        <div style="margin: 30px auto; width: 150px" v-show="moreBtnShow">
          <el-button round style="width: 150px;margin-bottom:50px;margin-left: 170px;" @click="more">点击加载更多</el-button>
        </div>


      </div>

      <!-- 右边我关注的 -->
      <div>
        <div style="">
          <el-carousel trigger="click" height="50px" class="block" indicator-position="none">
            <el-carousel-item v-for="item in 4" :key="item">
              <img src="https://img.alicdn.com/imgextra/i2/O1CN01BwXFn421nnDD1Nx11_!!6000000007030-2-tps-740-140.png"
                alt="" srcset="">
            </el-carousel-item>
          </el-carousel>
        </div>
        <followWithInterest style="width: 250px;"></followWithInterest>
      </div>
    </div>


    <!-- 播放弹窗 -->
    <div class="playPopup" v-if="playShow">
      <play :worksId.sync="worksId" ref="childRef" :isLive="false"></play>
    </div>

    <!-- 关闭按钮 -->
    <div class="closeBtn hand" v-show="playShow" @click="closeBtnFun">
      <i class="el-icon-circle-close closeIcon"></i>
    </div>




  </el-row>
</template>
  
<script>
// @ is an alias to /src
import followWithInterest from "@/components/followWithInterest/followWithInterest.vue";
import play from "@/components/play/play.vue";

export default {
  props: ["type"],
  components: {
    followWithInterest,
    play
  },
  data() {
    return {
      dataList: [],
      currentPlayer: null,
      page: 1,
      loading: true,
      hoverShow: -1,
      playShow: false,
      worksId: '',
      moreBtnShow: true,
      pageSize: 30,
      fits: ['fill', 'contain', 'cover', 'none', 'scale-down'],
      url: 'http:\/\/qiniu.hnmzkj.top\/images\/64f6bb368e4a9.jpg'
    };
  },

  mounted() {
    this.getShort();

  },

  methods: {
    closeBtnFun() {
      this.$nextTick(() => {
        this.$refs.childRef.empty(); // 调用子组件的方法
        this.playShow = false
      })

    },
    handleMouseEnter(index) {
      this.hoverShow = index
    },

    handleMouseLeave(index) {
      this.hoverShow = -1;
    },
    handleClick(id, type) {
      //新窗口打开
      // const url = 'http://localhost:8080'
      // window.open(url, '_blank');

      //跳转
      // this.$router.push({
      //   path: "/play",
      //   query: {
      //     id, type
      //   }, // 传递参数的方式，可以使用query来传递参数
      // });



      //  打开弹窗
      this.worksId = id
      console.log(id, "id4545")
      console.log(this.worksId, "worksId")
      this.playShow = true
      setTimeout(() => {
        this.$refs.childRef.childMethod(); // 调用子组件的方法
      }, 500)

    },
    getShort() {
      let that = this;
      let result = {
        worktype: that.type,
        page: that.page,
        pageSize: that.pageSize,
      };
      that.apis.getShort(result).then((res) => {
        if (res.data.length < that.pageSize) {
          that.moreBtnShow = false
        }
        if (that.page == 1) {
          that.dataList = res.data;
          that.loading = false;
        } else {
          //上拉加载
          that.dataList = [...that.dataList, ...res.data];
          that.loading = false;

        }
      });
    },

    more() {
      console.log();
      this.page++;
      this.getShort();
    },
  },
};
</script>
  
<style  lang="scss" scoped>
.imgBox {
  width: 140px;
  height: 140px;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.flexFS {
  display: flex;
  // justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.box {
  color: var(--themeColor);
}

.videoBox {
  width: 140px;
  height: 140px;
  overflow: hidden;
  margin-left: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  // background-color: #242323;
  position: relative;
}

.videoBox2 {
  margin-right: 15px;
  margin-left: 0;
  width: 155px;
  height: 155px;
}

.videoHoverBox {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 99;
  color: #fff;
  padding: 10px;
  font-size: 12px;
}

.flex {
  display: flex;
}

.block {
  width: 290px;
  height: 50px;
  border-radius: 30px;
  margin-bottom: 10px;
}

.title {
  font-size: 24px;
  color: #000;
  font-weight: 700;
  margin-right: 40px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.maxBox {
  border-radius: 10px;
  width: 430px;
  height: 590px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: 100% 100%;
  background: url('@/assets/maxbj.jpg');
  background-color: #000;
}

.maskBox {
  position: absolute;
  width: 430px;
  height: 590px;
  background-color: #000;
  opacity: 0.6;


}

.playPopup {
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 1);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  min-width: 1800px;
}

.closeBtn {
  position: fixed;
  top: 140px;
  left: 70px;
  width: 50px;
  height: 50px;
  z-index: 9999;
}

.closeIcon {
  font-size: 50px;
  color: rgb(85, 85, 85)
}

.closeIcon:hover {
  color: #c5c5c5;
}

.img {
  width: 100%;
  position: relative;
  z-index: 99;
}
</style>