<template>
    <div>
        <div class="box" style="min-width: 500px; ">
            <div>
                <span class="hand">
                    <a class="title" href="https://jbts.mct.gov.cn/" target="_blank">12318全国文化市场举报</a> &nbsp;
                </span>| &nbsp;
                <span class="hand">
                    <a class="title" href="https://www.piyao.org.cn/yybgt/index.htm" target="_blank">网络谣言曝光台</a> &nbsp;
                </span>| &nbsp;
                <span class="hand">
                    <a class="title" href="https://www.12377.cn/" target="_blank">网上有害信息举报</a> &nbsp;
                </span>
                <span>
                    &nbsp;｜ &nbsp; 违法和不良信息举报：19391771616 &nbsp; ｜ &nbsp; 青少年守护专线：19391771616 &nbsp; ｜ &nbsp;
                    网络内容从业人员违法违规行为举报：gzhw@miaiy.com
                </span>
            </div>
            <div style="margin-top: 7px;">
                <span>2023 @ 昊文网络</span> &nbsp; | &nbsp;
                <span class="hand">
                    <a class="title" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">黔ICP备2023005185号-4</a> &nbsp;
                </span>
                <span style="font-size: 14px;font-weight: 700;">&nbsp;｜&nbsp; 贵州昊文网络科技有限公司 &nbsp;｜&nbsp;</span>
                <span class="hand">
                    <a class="title" href="https://qiniu.hnmzkj.top/peixiu/gbds.jpg" target="_blank">广播电视节目制作经营许可证</a> &nbsp;
                </span>
                &nbsp; | &nbsp;
                <span class="hand">
                    <a class="title" href="https://qiniu.hnmzkj.top/peixiu/cbw.jpg" target="_blank">出版物经营许可证</a> &nbsp;
                </span>
                <span class="hand"> &nbsp;
               | &nbsp; <a class="title" href="https://qiniu.hnmzkj.top/dx.jpg" target="_blank">增值电信业务经营许可证 </a>
               &nbsp; </span>
                &nbsp; | &nbsp;
                <span class="hand">
                    <div style="display: flex;align-items: center;display: inline-block;">
                        <img alt src="@/assets/police.png" style="width: 14px;height: 14px;position: absolute;margin-top: 1px;" />
                        <a class="title" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=52010202003693" style="margin-left: 18px;" target="_blank">贵公网安备 52010202003693号</a>
                        &nbsp;
                    </div>
                </span>
            </div>

            <div style="margin-top: 7px;">
                <span @click="springen('about')" class="hand title">联系我们 &nbsp;</span>
                &nbsp; | &nbsp;
                <span @click="springen('urheberrecht')" class="hand title">版权声明 &nbsp;</span>
                &nbsp; | &nbsp;
                <span @click="springen('protokoll')" class="hand title">用户协议 &nbsp;</span>
                &nbsp; | &nbsp;
                <span class="hand title">联系方式:18198640015 &nbsp;</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        springen(url) {
            this.$router.push({ path: url })
        },
    },
}
</script>

<style lang="scss" scoped>
.box {
    padding: 50px 0;
    background-color: rgb(29, 29, 29);
    text-align: center;
    color: rgb(153, 153, 153);
    width: 100%;
    font-size: 12px;
}

.title {
    color: rgb(153, 153, 153);
    text-decoration: none;
}

.title:hover {
    color: #fff;
}
</style>