import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/global.css'
import './assets/css/alex.scss'
import apis from './utils/http';
import VueLazyload from "vue-lazyload";
import VueQrcode from '@chenfengyuan/vue-qrcode';
Vue.prototype.apis = apis;
Vue.use(VueLazyload);

import navbar from "@/components/navbar/navbar.vue";
Vue.component("navbar", navbar)
import bottom from "@/components/bottom/bottom.vue";
Vue.component("bottom", bottom)





Vue.component(VueQrcode.name, VueQrcode);

Vue.config.productionTip = false

Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')  //示将Vue实例挂载到id为app的DOM元素上，即将Vue应用渲染到页面上。






