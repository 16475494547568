const apiArr = [
  // {
  //   name: "test", // 接口调用方法名
  //   path: '/api/test', // 后端路径
  //   method: "POST", // 默认POST
  //   headers: {"token": 123}, // 自定义单个接口的请求头
  //   desc: "登录接口", // 接口描述
  // }
  {
    name: "login",
    path: '/api/login',
    desc: "测试接口",
  },
  {
    name: "getShort",
    path: 'api/creation/getList',
    method: "GET",
    desc: "获取视频",
  },
  {
    name: "getComment",
    path: 'api/creation/getCommentList',
    method: "GET",
    desc: "获取视频评论",
  },
  {
    name: "getWorksDetails",
    path: 'api/creation/find',
    method: "GET",
    desc: "根据作品ID获取作品详情",
  },
  {
    name: "login",
    path: 'api/user/login',
    method: "POST",
    desc: "登录",
  },
  {
    name: "register",
    path: 'api/user/register',
    method: "POST",
    desc: "注册",
  },
  {
    name: "getcode",
    path: '/api/user/getCode',
    method: "POST",
    desc: "获取验证码",
  },
  {
    name: "authentication",
    path: 'api/aliVerify/web_verify',
    method: "POST",
    desc: "提交信息获取实名认证参数",
  },
  
  
]

export default apiArr;
