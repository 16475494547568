import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    personality: "#ffd727",
    themeColor: "--themeColor:#ffd727",
    color:{
      personality: "#ffd727",
    }
  },
  getters: {
    getThemeColor:state => {
      return state.personality;
    }
  },

  //只能在這裡修改state裡面的数据
  mutations: {
    setThemeColor(state, newColor) {
      state.personality = color;
      state.themeColor = newColor;
    }
  },

  //这里执行异步操作
  actions: {
    upThemeColor({ commit }, newColor) {
      commit('setThemeColor', newColor);
    }
  },
  modules: {
  }
})

