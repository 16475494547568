// import { getSession } from '@/utils';

const config = {
    baseURL: 'https://api.miaiy.com/', //'http://localhost:8080/',
    timeout: 15000,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    },
    // 自定义全局请求头
    // headerHandlers: [
    //   () => new Promise((resolve) => {
    //     const userInfo = getSession('userInfo');
    //     resolve({'session': userInfo ? userInfo.session : ''});
    //   })
    // ],

    publicKey:"-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDGvR3+575LRx2im2ZKEPhS5X2MqpRsSJCLj6ZI/RtKdeeGm5QRhNoF89H9FQnK2CoTn/waS012mDizQSXKYlzmlSsUI5FrCgtsSjwxlN2omNpcarf69e9pAcULTUr/JHj1EFmmz4Fv/yrTZH2eB0AXGgc5HL5IylWM8jqj9sqBpQIDAQAB-----END PUBLIC KEY-----",
    
    privateKey:"-----BEGIN RSA PRIVATE KEY-----MIICXQIBAAKBgQDGvR3+575LRx2im2ZKEPhS5X2MqpRsSJCLj6ZI/RtKdeeGm5QRhNoF89H9FQnK2CoTn/waS012mDizQSXKYlzmlSsUI5FrCgtsSjwxlN2omNpcarf69e9pAcULTUr/JHj1EFmmz4Fv/yrTZH2eB0AXGgc5HL5IylWM8jqj9sqBpQIDAQABAoGAZHf4sQOgvNeP0MN0u57GmsmvZaxaaj9o61TMgHuMD2F5kSAXg5/AxF+VWbcIA0ayRseLIBPPblPX3i7iFuURBxBg780totwpNZsxvFBvZhaSbpgcq81xnBwtwnOJN0oB38ViD8awgiZI4R6i4TJcEViP7fhMiLVcVNVaSzivxeECQQDnj7+b/RjxuIuANCE7awA0Kfqatwv0d5M72nePIEPhx4auTufMDDTD4BYK8aa0ptlrmQyBtrfgz/PlL9SAorh5AkEA27aS6HK7RnHxKMyf5gKbHmSyHyno+Fra2fAO8DOAvDh15DvdZcnNtkvZZPuJQEasbRrhwNlOFzvht5jfbuZfjQJAI1GdnCFCiLvpKyjIWNO9ODee7bI1tJf4cQylZ8M4F1vjgvquCEVkVuv68LhjAPLh/rNdYPuJmFSfkrl05BntoQJBAMs9LNHm54N3fPwfpL2wpWrM/NPFAeNSr+UvobHiAnCZzbvtAJTawvahXRyDbWfOuEtItZGdshXd56Lcv7onUjUCQQCvw8tkERHG8+cvjBIZ/um+pjj+NbusLgVNGphrWBeSlAsjnXI4/O1f5HZiNq57R9gMlgIvCi3J/DDFstbkdyIh-----END RSA PRIVATE KEY-----"
    
  }
  
  export default config;
  